uniform vec3 uDepthColor;
uniform vec3 uSurfaceColor;
uniform float uColorOffset;
uniform float uColorMultiplier;

#include ../includes/lights/ambientLight.glsl
#include ../includes/lights/pointLight.glsl
#include ../includes/lights/directionalLight.glsl

varying float vElevation;
varying vec3 vNormal;
varying vec3 vPosition;



void main()
{
    vec3 viewDirection = normalize(vPosition - cameraPosition);
    vec3 normal = normalize(vNormal);

    // Base Color
    float mixStrength = (vElevation + uColorOffset) * uColorMultiplier;
    mixStrength = smoothstep(0.0, 1.0, mixStrength);
    vec3 color = mix(uDepthColor, uSurfaceColor, mixStrength);

    vec3 light = vec3(0.0);
    
     light += pointLight(
        vec3(1.0),      
        10.0,
        normal,
        vec3(0., 0.25, 0.),
        viewDirection,
        30.0,
        vPosition,
        0.95

    );
// vec3 directionalLight(vec3 lightColor, float lightIntensity, vec3 normal,  vec3 lightPosition, vec3 viewDirection, float specularPower){

    color *= light;
    // Final Color
    gl_FragColor = vec4(color, 1.0);
    // ces includes permettent de changer les tonnemapping ou le colorSpace de three js et que ce influe sur le shaderMaterial
    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}