uniform float uTime;
uniform float uBigWavesElevation;
uniform vec2 uBigWavesFrequency;
uniform float uBigWavesSpeed;

uniform float uSmallWavesElevation;
uniform float uSmallWavesFrequency;
uniform float uSmallWavesSpeed;
uniform float uSmallIterations;

varying float vElevation;
varying vec3 vNormal;
varying vec3 vPosition;
// ! ON COMPUTE LES NORMALS DANS LE VERTEX SHADER pas depuis le js sinon les normals d'un plan sont toutes vers le haut pas default
#include ../includes/perlinClassic.glsl

float waveElevation(vec3 position){
        float elevation = sin(position.x * uBigWavesFrequency.x + uTime * uBigWavesSpeed) *
                      sin(position.z * uBigWavesFrequency.y + uTime * uBigWavesSpeed) *
                      uBigWavesElevation;
    for(float i = 1.0; i <= uSmallIterations; i++)
    {
        elevation -= abs(perlinClassic3D(vec3(position.xz * uSmallWavesFrequency * i, uTime * uSmallWavesSpeed)) * uSmallWavesElevation / i);
    }
    return elevation;
}

void main()
{
    //Base position
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    // Elevation
    float shift = 0.01;
    vec3 modelPosA = modelPosition.xyz + vec3(shift, 0., 0.);
    // -shift car regle de la main droite on veut un produit vectoriel (cross product) positif
    vec3 modelPosB = modelPosition.xyz + vec3(0., 0., -shift);
    float elevation = waveElevation(modelPosition.xyz);
    
    float elevationA = waveElevation(modelPosA);
    float elevationB = waveElevation(modelPosB);
    modelPosition.y += elevation;
    modelPosA.y += elevationA;
    modelPosB.y += elevationB;

    vec3 toA = normalize( modelPosA- modelPosition.xyz);
    vec3 toB = normalize(modelPosB - modelPosition.xyz);



    vec3 computedNormal = cross(toA, toB);

    

    // Final position
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    gl_Position = projectedPosition;
    // varyings
    vElevation = elevation;
    vNormal = computedNormal;
    vPosition = modelPosition.xyz;
    
}